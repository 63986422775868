export default class StageLarge extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		this._addEventListener();
		this._handleCustomVH();
	}

	disconnectedCallback() {
		this._removeEventListener();
	}

	_addEventListener() {
		window.addEventListener('resize', this._handleCustomVH);
	}

	_removeEventListener() {
		window.removeEventListener('resize', this._handleCustomVH);
	}

	/**
	 * private function setting the relative vh
	 * @returns {void}
	 */
	_handleCustomVH() {
		let vh = window.innerHeight * 0.01;
		window.document.documentElement.style.setProperty('--custom-vh', `${vh}px`);
	}
}

if (window.customElements.get('audi-stage-large') === undefined) {
	window.customElements.define('audi-stage-large', StageLarge);
}
